import './css/HomePanel.css'
import React, {useState} from 'react'
import Panel from '../Panel'

import ReactStoreBadges from 'react-store-badges'

import main from '../assets/homepage/screenshots/main.jpg'
import dif from '../assets/homepage/screenshots/dif.jpg'
import edit from '../assets/homepage/screenshots/edit.jpg'
import weeksettings from '../assets/homepage/screenshots/weeksettings.jpg'
import calendar from '../assets/homepage/screenshots/calendar.jpg'
import takebreak from '../assets/homepage/screenshots/takebreak.jpg'
import pending from '../assets/homepage/screenshots/pending.jpg'
import simulator from '../assets/homepage/screenshots/simulator.jpg'
import ebbinghaus from '../assets/homepage/screenshots/ebbinghaus.png'
import stude_icon from '../assets/stude_icon.png'

const main_title = 'Every day, a to-do list.'
const main_description = 'Your scheduled items, beautifully arranged'
const dif_title = 'Stude adapts to you.'
const dif_description = "Whenever you review an item, choose how difficult it was,\nand Stude will compute the next review accordingly."
const edit_title = 'Manage your items.'
const edit_description = 'A whole bunch of customization options,\nfor each subject, for each item.'
const weeksettings_title = 'Never feel overwhelmed again.'
const weeksettings_description = 'Stude allows you to define your page goal for each day of the week,\n as well as the desired type of studying.'
const calendar_title = 'Keep an overview....'
const calendar_description = 'Stude allows you to preview the upcoming revisions.'
const takebreak_title = '...and give yourself some breaks!'
const takebreak_description = 'With Stude, you can take a break from studying, but also\nplan a light / heavy studying period.'
const pending_title = 'Pending lists, to keep moving forward.'
const pending_description = 'Define a list of items that you would like to learn, and Stude\nwill automatically incorporate them into your days.'
const simulator_title = 'Simulations, to find your ideal settings.'
const simulator_description = "Once you've defined your studying goals for the week, you can predict whether delay will pile up or not."

const HomePanel = ({state, set_state}) => {

    const ScreenPresentation = ({src=null, text=null, title=null, left=true, optionalImage=null}) => {
        if (src == null || text == null) {
            return <div />
        }
        return <div style={{
            height: imageStyle.height + 20,
            width: '95%',
            backgroundColor:'#18212A',
            marginTop: 10,
            marginBottom: 10,
            display:'inline-flex',
            padding: 20,
            paddingTop: 20,
            paddingBottom: 20,
            alignSelf:'center',
            borderRadius: 20
        }}>
            {left == true ?
            <>
                <img src={src} style={imageStyle} />
                <div style={{alignSelf:'center', width: '100%'}}>
                    <div style={sectionTitleStyle}>
                        {title}
                    </div>
                    <div style={{height: 20}}/>
                    <div style={descriptionStyle}>
                        {text}
                    </div>
                    
                </div>
                    {
                        optionalImage == null ? null :
                        <img src={optionalImage} style={{height: 50}} />
                    }
            </>
            :
            <>  
                    {
                        optionalImage == null ? null :
                        <div style={{ alignSelf:'center', textAlign:'center'}}>
                            <img src={optionalImage} style={{height: 290, marginTop: 20}} />
                        </div>
                    }
                <div style={{alignSelf:'center', width: '100%'}}>
                    <div style={sectionTitleStyle}>
                        {title}
                    </div>
                    <div style={{height: 20}}/>
                    <div style={descriptionStyle}>
                        {text}
                    </div>
                    
                </div>
                
                <img src={src} style={imageStyle} />
            </>
            }
        </div>
    }

    return (
        <Panel>
            <div className='titlebanner'>
                <div className='bannerText'>
                    Stude, the ultimate planner for students.
                </div>
            </div>
            <div className='downloadbanner' style={{display: 'flex', flexDirection:'column'}}>
                <div className='downloadText'>
                    Get Stude on your device !
                </div>
                <div style={{marginTop: 40, flexDirection:'row', display: 'flex'}}>
                    <ReactStoreBadges
                        platform='ios'
                        url='https://apps.apple.com/us/app/stude/id1645336650'
                        locale={'en-us'}
                        width={300}
                    />
                    <img src={stude_icon} style={{width: 60, height: 60, alignSelf:'center', marginLeft: 40, marginRight: 40}}/>
                    <ReactStoreBadges
                        platform='android'
                        url='https://play.google.com/store/apps/details?id=com.studeapps.stude'
                        locale={'en-us'}
                        width={300}
                    />
                </div>
            </div>
            <div style={{marginTop: 30, display: 'flex', flexDirection:'column'}}>
                <ScreenPresentation src={main} text={main_description} title={main_title}/>
                <ScreenPresentation src={dif} text={dif_description} title={dif_title} left={false} optionalImage={ebbinghaus}/>
                <ScreenPresentation src={edit} text={edit_description} title={edit_title}/>
                <ScreenPresentation src={weeksettings} text={weeksettings_description} title={weeksettings_title} left={false}/>
                <ScreenPresentation src={calendar} text={calendar_description} title={calendar_title} />
                <ScreenPresentation src={takebreak} text={takebreak_description} title={takebreak_title} left={false}/>
                <ScreenPresentation src={pending} text={pending_description} title={pending_title} />
                <ScreenPresentation src={simulator} text={simulator_description} title={simulator_title} left={false}/>
            </div>
        </Panel>
    )
}

const imageStyle = {
    height: 450,
    marginLeft: 30,
    marginRight: 30,
    borderRadius: 15,
    alignSelf:'center'
}

const sectionTitleStyle = {
    fontFamily: 'Inter',
    fontWeight: '200',
    fontSize: 30,
    color: '#CDE1FB',
    textAlign: 'center',
    width: '100%',
}

const descriptionStyle = {
    fontFamily: 'Inter',
    fontWeight: '200',
    fontSize: 22,
    color: '#CDE1FB',
    textAlign: 'center',
    width: '100%',
    alignSelf:'center',
    whiteSpace: 'break-spaces'
}

export default HomePanel;