import React, {useEffect, useState} from 'react'
import Panel from '../Panel'
import {FiCheckCircle} from 'react-icons/fi'
import {useParams} from 'react-router-dom'
import NotFound from './NotFound'
import remoteAPI from '../api/remoteAPI'
import { RotateLoader } from 'react-spinners'


const EmailConfirm = ({state, set_state}) => {
    const token = useParams().token
    const [success, set_success] = useState(null)

    useEffect(() => {
        const confirmMethod = async (token) => {
            if (token != null && token != '') {
                const response = await remoteAPI.post('/auth/confirm-email/' + token, {});
                if (response.data.success) {
                    set_success(true)
                } else {
                    set_success(false)
                }
            }
        }
        confirmMethod(token)
    }, [token])
    return <Panel>
        {
            token != null && token != ''
            ?
                (

                success != null ? 
                
                (
                success == true ?
                
                    <div style={{}}>
                    <div style={{display: 'flex', flexDirection:'row', alignItems:'center', marginTop: 40, justifyContent:'center'}}>
                        <FiCheckCircle size={45} color='green' />
                        <div style={{fontSize: 40, color: 'white', fontFamily:'Inter', textAlign:'center', fontWeight: '200', marginLeft: 20}}>
                            Success!
                        </div>
                    </div>
                    <div style={{fontSize: 30, color: 'white', fontFamily:'Inter', flex: 1, textAlign:'center', fontWeight: '200', marginTop: 20}}>
                        Your account was successfully activated!
                    </div>
                    </div>
                : <NotFound />
                )

                : 
                <div style={{display: 'flex', width: '100%', height: 100, alignItems:'center', justifyContent:'center'}}>
                    <RotateLoader 
                        color="white"
                    /> 
                </div>
                
                )
                
            : <NotFound />
        }
        
    </Panel>
}


export default EmailConfirm;

const styles = {
    usernameContainer: {
        width: 600,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20
    },
    planContainer: {
        width: 300,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20,
        flexDirection: 'column',
        padding: 20
    },
    managementContainer: {
        width: 300,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20,
        padding: 20,
        flexDirection: 'column'
    },
    mainContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    title: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 25,
        color: '#CDE1FB',
        textAlign: 'center',
        width: '100%',
        marginBottom: 10
    },
    planFieldContainer: {
        minHeight: 50,
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 20,
        color: '#CDE1FB',
        textAlign: 'left',
        width: '100%',
        flexDirection:'row',
        display:'flex',
        marginBottom: 2,
        marginTop: 8,
        alignItems:'center'
    },
    logoutContainer: {
        minHeight: 50,
        minWidth: 50,
        display: 'flex',
        marginTop: 20
    }, 
    logoutButton: {
        height: 50,
        width: 50,
        //backgroundColor: '#18212A',
        borderWidth: 0,
        borderRadius: 15,
        marginLeft: 'auto',
        marginRight: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}
