import React from 'react';


const TermsOfUse = () => {
    return <div style={styles.container}>

<strong style={{...styles.headerStyle}}>Conditions Générales d’Utilisation, de Vente et de Prestation de Services</strong>
<p style={{...styles.bodyStyle}}>En vigueur au 29/09/2022</p>

<strong style={styles.headerStyle}>ARTICLE 1 - Champ d'application</strong>
<p style={styles.bodyStyle}>
Les présentes Conditions Générales de Vente (ci-après « CGV ») et Conditions Générales d’Utilisation (ci-après « CGU ») s'appliquent, sans restriction ni réserve à l’utilisation du Service sur internet et sur l’application mobile STUDE, ainsi qu’à tout achat en ligne des Services proposés par le Prestataire, entre lui et le Client non professionnel (Le Client), par tout processus mis à sa disposition (application uniquement). Chaque achat est dénommé Commande. Ainsi, toute installation ou utilisation de l’application STUDE et chaque Commande convenue inclura ces conditions.<br/>
Le Prestataire se réserve le droit de compléter, modifier à tout moment les présentes Conditions, sans avis préalable et sans responsabilité envers le Client. Il informera néanmoins le Client via l’adresse e-mail fournie en cas de mise à jour des CGU-CGV<br/>
<br/>
Chacun des Services proposés est détaillé sur le site internet https://www.stude.app/plans. Le Client est tenu d’en prendre connaissance avant toute passation de Commande. Le choix et l’achat d’un Service est de la seule responsabilité du Client.<br/>
<br/>
Les présentes conditions valent pour les contrats présents et à venir entre le Client non professionnel et le Prestataire.<br/>
<br/>
Celles-ci sont accessibles à tout moment sur le site https://www.stude.app/terms-of-use et sont réputées prévaloir sur tout autre document. Des conditions différentes, contradictoires ou complémentaires ne feront partie de la Commande que si, et dans la seule mesure où, le Prestataire les aura acceptées par écrit.<br/>
<br/>
Conformément aux réglementations en vigueur, l’expression « par écrit » inclut l’écrit électronique.<br/>
<br/>
Le Client déclare avoir pris connaissance des présentes CGU-CGV et les avoir acceptées avant toute installation ou utilisation de l’application STUDE, ainsi qu’avant toute Commande.<br/>
 <br/>
Sauf preuve contraire, les données enregistrées dans le système informatique du Prestataire constituent la preuve de l’ensemble des transactions conclues avec le Client. Toute contestation ou demande de la part du Client devra revêtir la forme d’un écrit au sens de la réglementation en vigueur (un e-mail suffit).<br/>
<br/>
Les coordonnées du Prestataire sont les suivantes :<br/>
Hawari Ali<br/>
11 rue du Sudel<br/>
Numéro d'immatriculation : 919 186 148<br/>
Email : <a style={styles.linkStyle} href='mailto:admin@stude.app'>admin@stude.app</a><br/>
Téléphone : +33 7 82 06 43 77<br/>
<br/>
En ce qui concerne les prestations payantes, des droits de douane, taxes locales ou droits d'importation ou taxes d'état sont susceptibles d'être exigibles suivant la réglementation en vigueur. Ils seront à la charge exclusive et relèvent de la seule responsabilité du Client.<br/>
<br/>
</p>

<strong style={styles.headerStyle}>ARTICLE 2 - Prix</strong>
<p style={styles.bodyStyle}>
2.1 Prestations gratuites<br/>
<br/>
Des prestations gratuites sont disponibles dans l’application, à savoir de manière non exhaustive les fonctions de planification de révisions, le paramétrage utilisateur, l’association de notes et de fichiers à chacun des items ajoutés par le Client. Le Prestataire se réserve néanmoins le droit de mettre en œuvre des mises à jour augmentant, réduisant, restreignant ou modifiant le champ de ces prestations gratuites, sous forme notamment de modification de la conception, méthode opérationnelle, spécifications techniques et ce dans le respect de la réglementation en vigueur, notamment dans la mesure où ces changements sont nécessaires pour maintenir la conformation des Prestations ou pour des motifs techniques, économiques ou juridiques impérieux.<br/>
<br/>
2.2 Prestations payantes<br/>
<br/>
 Les Services sont fournis aux tarifs en vigueur figurant sur le Store à partir duquel l’application a été téléchargée (App Store ou Google Play Store) lors de l'enregistrement de la commande par le Prestataire. Les prix sont exprimés en Euros TTC et tiennent compte d'éventuelles réductions qui seraient consenties par le Prestataire. Ces tarifs sont fermes et non révisables pendant leur période de validité mais le Prestataire se réserve le droit, hors période de validité, d’en modifier les prix à tout moment. Néanmoins, le prix figurant dans l’application le jour de l’achat sera le seul applicable à l’échateur.<br/>
<br/>
Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais. Une facture est établie par le Store et remise au Client par e-mail lors de la fourniture des Services commandés.<br/>
<br/>
Les prestations payantes sont les suivantes :<br/>
<ul>
<li>Sauvegarde en ligne des données utilisateur (à l’exception des fichiers que le Client aura pu associer à ses items)</li>
<li>Synchronisation des données de l’application entre les appareils connectés au compte du Client</li>
<li>Désactivation des publicités au sein de l’application </li>
</ul>
Le Prestataire se réserve néanmoins le droit de mettre en œuvre des mises à jour augmentant, réduisant, restreignant ou modifiant le champ de ces prestations payantes, sous forme notamment de modification de la conception, méthode opérationnelle, spécifications techniques et ce dans le respect de la réglementation en vigueur, notamment dans la mesure où ces changements sont nécessaires pour maintenir la conformation des Prestations ou pour des motifs techniques, économiques ou juridiques impérieux.<br/>
</p>

<strong style={styles.headerStyle}>ARTICLE 3 - Commandes et compte utilisateur</strong>
<p style={styles.bodyStyle}>
Il appartient au Client de sélectionner sur l’application STUDE les Services qu'il désire commander, selon les modalités suivantes :<br/>
<ul>
<li>Après création d'un compte utilisateur (sur le site internet ou l’application) et confirmation de l’adresse e-mail, le Client peut se connecter sur l’application STUDE.</li>
<li>Sur cette dernière, dans son espace utilisateur, il trouvera un bouton ‘’Mettre à niveau’’, lui permettant, après choix de la formule souhaitée, de souscrire à un abonnement via le Store à partir duquel l’application a été installée. En souscrivant, il consent aux présentes CGU-CGV.</li>
<li>Une fois le paiement effectué, une facture est envoyée (par le Store à partir duquel a été installée l’application) sur l'adresse mail associée à l’appareil du Client.</li>
</ul>
La vente ne sera considérée comme valide qu’après paiement intégral du prix. Il appartient au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur.<br/>
Le Prestataire se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il existerait un litige relatif au paiement d'une commande antérieure.<br/>
Le Client pourra gérer sa commande depuis l’espace de gestion des abonnements de son Store :<br/>
<ul>
<li><a style={styles.linkStyle} href='https://play.google.com/store/account/subscriptions'>Google Play Store</a></li>
<li><a style={styles.linkStyle} href='https://apps.apple.com/account/subscriptions'>App Store</a></li>
</ul>
<br/>
La passation d'une commande sur l’application STUDE implique la conclusion d'un contrat d'une durée minimum de 1 mois renouvelable pour une même durée par tacite reconduction.<br/>
Aux termes de l'article L 215 -1 du Code de la consommation, reproduit ci-dessous :<br/>
« Pour les contrats de prestations de services conclus pour une durée déterminée avec une clause de reconduction tacite, le professionnel prestataire de services informe le consommateur par écrit, par lettre nominative ou courrier électronique dédiés, au plus tôt trois mois et au plus tard un mois avant le terme de la période autorisant le rejet de la reconduction, de la possibilité de ne pas reconduire le contrat qu'il a conclu avec une clause de reconduction tacite. Cette information, délivrée dans des termes clairs et compréhensibles, mentionne, dans un encadré apparent, la date limite de non-reconduction.<br/>
Lorsque cette information ne lui a pas été adressée conformément aux dispositions du premier alinéa, le consommateur peut mettre gratuitement un terme au contrat, à tout moment à compter de la date de reconduction.<br/>
Les avances effectuées après la dernière date de reconduction ou, s'agissant des contrats à durée indéterminée, après la date de transformation du contrat initial à durée déterminée, sont dans ce cas remboursées dans un délai de trente jours à compter de la date de résiliation, déduction faite des sommes correspondant, jusqu'à celle-ci, à l'exécution du contrat. Les dispositions du présent article s'appliquent sans préjudice de celles qui soumettent légalement certains contrats à des règles particulières en ce qui concerne l'information du consommateur. »<br/>
L'article L215-2 du Code de la consommation exclut l'application de l'art L215-1 aux exploitants des services d'eau potable et d'assainissement, à l’inverse l'article L215-3 du Code de la consommation, dispose que ces règles sont applicables aux contrats conclus entre des professionnels et des non-professionnels. <br/>
L'article L241-3 du sanctionne le professionnel qui n'aurait pas procédé aux remboursements dans les conditions prévues à l'article L 215-1 du Code de la consommation"<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 4 - Conditions de paiement</strong>
<p style={styles.bodyStyle}>
Le prix est payé par voie de paiement sécurisé, selon les modalités de paiement autorisées par le Store à partir duquel a été installée l’application. Les Stores concernés sont l’App Store ainsi que le Google Play Store.<br/>
Le prix est payable comptant par le Client, en totalité au jour de la passation de la commande.<br/>
<br/>
Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif des sommes dues, par le Prestataire.<br/>
Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si celui-ci ne lui en paye pas le prix en totalité dans les conditions ci-dessus indiquées.<br/>
<br/>
Si le Client pense être sujet à une erreur de prélèvement de la part du Prestataire, il doit immédiatement contacter le Prestataire dans les 30 jours par mail à l’adresse <a style={styles.linkStyle} href='mailto:support@stude.app'>support@stude.app</a>. Aucune demande ne sera traitée une fois ce délai dépassé.<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 5 - Fourniture des Prestations</strong>
<p style={styles.bodyStyle}>
Les Services commandés par le Client seront fournis selon les modalités suivantes : <br/>
Une fois que le Client a souscrit à l'abonnement Stude +, les fonctionnalités sont (sauf problème) immédiatement disponibles sur l'application STUDE.<br/>
Lesdits Services seront fournis théoriquement immédiatement, un délai de 24h peut toutefois être observé, à compter de la validation définitive de la commande du Client, dans les conditions prévues aux présentes CGU-CGV.<br/><br/>
Le Prestataire s'engage à faire ses meilleurs efforts pour fournir les Services commandés par le Client, dans le cadre d'une obligation de moyen et dans les délais ci-dessus précisés.<br/>
<br/>
Si les Services commandés n'ont pas été fournis dans un délai de 24 heures après la date indicative de fourniture, pour toute autre cause que la force majeure ou le fait du Client, la vente des Services pourra être résolue à la demande écrite (un e-mail suffit) du Client dans les conditions prévues aux articles L 216-2, L 216-3 et L241-4 du Code de la consommation. Les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat, à l'exclusion de toute indemnisation ou retenue.<br/>
En cas de demande particulière du Client concernant les conditions de fourniture des Services, dûment acceptées par écrit par le Prestataire, les coûts y étant liés feront l'objet d'une facturation spécifique complémentaire ultérieure.<br/>
<br/>
A défaut de réserves ou réclamations expressément émises par le Client lors de la réception des Services, ceux-ci seront réputés conformes à la commande, en quantité et qualité.<br/>
Le Client disposera d'un délai de 14 jours à compter de la fourniture des Services pour émettre des réclamations par mail à l'adresse <a style={styles.linkStyle} href='mailto:support@stude.app'>support@stude.app</a>, avec tous les justificatifs y afférents, auprès du Prestataire. Aucune réclamation ne pourra être valablement acceptée en cas de non-respect de ces formalités et délais par le Client. Le Prestataire remboursera ou rectifiera dans les plus brefs délais et à ses frais les Services dont le défaut de conformité aura été dûment prouvé par le Client.<br/>
<br/>
Le Prestataire se réserve le droit de geler et/ou suspendre l’utilisation des Services Payants au Client, si un comportement allant à l’encontre des présentes CGU-CGV est constaté ou suspecté.<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 6 - Droit de rétractation</strong>
<p style={styles.bodyStyle}>
Selon les modalités de l’article L221-18 du Code de la Consommation «
Pour les contrats prévoyant la livraison régulière de biens pendant une période définie, le délai court à compter de la réception du premier bien. »<br/>
Le droit de rétractation peut être exercé en ligne sur le support du Store à partir duquel l’application a été installée, ainsi qu’à l'aide du formulaire de rétractation ci-joint et également disponible sur le site ou de toute autre déclaration, dénuée d'ambiguïté, exprimant la volonté de se rétracter et notamment par courrier postal adressé au Prestataire aux coordonnées postales ou mail indiquées à l’ARTICLE 1 des présentes CGU-CGV.<br/>
En cas d'exercice du droit de rétractation dans le délai susvisé, seul le prix des Services commandés est remboursé.<br/>
Le remboursement des sommes effectivement réglées par le Client sera effectué dans un délai de 14 jours à compter de la réception, par le Prestataire, de la notification de la rétractation du Client.<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 7 - Responsabilité du Prestataire - Garanties</strong>
<p style={styles.bodyStyle}>
Le Prestataire garantit, conformément aux dispositions légales et sans paiement complémentaire, au Client, contre tout défaut de conformité ou vice caché, provenant d'un défaut de conception ou de réalisation des Services commandés dans les conditions et selon les modalités suivantes :<br/>
Dispositions relatives aux garanties légales<br/>
Article L217-4 du Code de la consommation<br/>
« Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité. »<br/>
Article L217-5 du Code de la consommation<br/>
« Le bien est conforme au contrat :<br/>
1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :<br/>
<ul>
- s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ;<br/>
- s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l'étiquetage ;<br/>
</ul>
2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté. »<br/>
Article L217-12 du Code de la consommation<br/>
« L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien. »<br/>
Article L217-16 du Code de la consommation.<br/>
« Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été consentie lors de l'acquisition ou de la réparation d'un bien meuble, une remise en état couverte par la garantie, toute période d'immobilisation d'au moins sept jours vient s'ajouter à la durée de la garantie qui restait à courir. Cette période court à compter de la demande d'intervention de l'acheteur ou de la mise à disposition pour réparation du bien en cause, si cette mise à disposition est postérieure à la demande d'intervention. »<br/>
Afin de faire valoir ses droits, le Client devra informer le Prestataire, par écrit (mail ou courrier), de l'existence des vices ou défauts de conformité.<br/>
Le Prestataire remboursera ou rectifiera ou fera rectifier (dans la mesure du possible) les services jugés défectueux dans les meilleurs délais et au plus tard dans les 14 jours jours suivant la constatation par le Prestataire du défaut ou du vice. Ce remboursement pourra être fait par virement ou chèque bancaire.<br/>
La garantie du Prestataire est limitée au remboursement des Services effectivement payés par le Client.<br/>
Le Prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou inexécution consécutif à la survenance d'un cas de force majeure habituellement reconnu par la jurisprudence française.<br/>
Les Services fournis par l'intermédiaire du site https://www.stude.app du Prestataire sont conformes à la réglementation en vigueur en France. La responsabilité du Prestataire ne saurait être engagée en cas de non-respect de la législation du pays dans lequel les Services sont fournis, qu'il appartient au Client, qui est seul responsable du choix des Services demandés, de vérifier.<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 8 - Données personnelles</strong>
<p style={styles.bodyStyle}>
Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente des Services et leur réalisation et leur délivrance, ainsi qu'à leur transmission à des tiers intervenant dans la réalisation des Services. Ces données à caractère personnel sont récoltées uniquement pour l’exécution du contrat de prestations de services, conformément à l’article 6 du Règlement Général sur la Protection des Données.<br/>
<br/>
8.1 Collecte des données à caractère personnel<br/>
<br/>
Les données à caractère personnel qui sont collectées sur le site https://www.stude.app et l’application STUDE sont les suivantes :<br/>
<br/>
Ouverture de compte<br/>
Lors de la création du compte Client / utilisateur : <br/>
Nom d’utilisateur et adresse e-mail.<br/>
<br/>
Paiement<br/>
Dans le cadre du paiement des Prestations proposées, le Prestataire ne collecte ni ne traite aucune donnée bancaire. Les Stores (App Store et Google Play Store) ne communiquent pas aux développeurs d’applications mobiles les données relatives aux transactions. Ces données sont exclusivement collectées et traitées par les Stores, en aucun cas le Prestataire ne peut y accéder. Il est à noter que les Stores mettent en œuvre toutes les mesures techniques et organisationnelles permettant la sécurité du traitement des données.<br/>
<br/>
8.2 Destinataires des données à caractère personnel<br/>
<br/>
Les données à caractère personnel sont utilisées par le Prestataire et ses co-contractants pour l’exécution du contrat et pour assurer l’efficacité de la prestation de services, sa réalisation et sa délivrance, dans le respect des dispositions de l’article 6 du Règlement Général sur la Protection des Données (RGPD).<br/>
La ou les catégorie(s) de co-contractant(s) est (sont) :<br/>
<ul>
•	Les prestataires établissements de paiement<br/>
</ul>
8.3 Responsable de traitement<br/>
<br/>
Le responsable de traitement des données est le Prestataire, au sens de la loi Informatique et libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la protection des données à caractère personnel.<br/>
<br/>
À titre de rappel, les coordonnées du Prestataire sont les suivantes :<br/>
Hawari Ali<br/>
11 rue du Sudel<br/>
Numéro d'immatriculation : 919 186 148<br/>
Email : <a style={styles.linkStyle} href='mailto:admin@stude.app'>admin@stude.app</a><br/>
Téléphone : +33 7 82 06 43 77<br/>
<br/>
8.4 Limitation du traitement<br/>
<br/>
Sauf si le Client exprime son accord exprès, ses données à caractère personnelles ne sont pas utilisées à des fins publicitaires ou marketing.<br/>
<br/>
8.5 Sécurité et confidentialité<br/>
<br/>
Le Prestataire met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois il est à signaler qu’Internet n’est pas un environnement complètement sécurisé et le Prestataire ne peut garantir la sécurité de la transmission ou du stockage des informations sur Internet.<br/>
<br/>
8.6 Mise en œuvre des droits des Clients et utilisateurs<br/>
<br/>
En application du Chapitre III du RGPD intitulé « Droits de la personne concernée », le Client dispose d’un droit d’accès à ses données personnelles, de rectifier ses données personnelles, de demander à voir effacer ses données à caractère personnel, de limiter le traitement de ses données, d’un droit à la portabilité des données et d’opposition au traitement de ses données. L’exercice de ces droits se traduit comme-suit :<br/>
<ul>
<li>En se connectant à son compte, le Client dispose d'un bouton "Delete my account", aussi bien sur le site internet que sur l’application STUDE. Après avoir cliqué dessus et avoir confirmé sa volonté de supprimer son compte, toutes ses données seront supprimées.</li>
<li>En cas de problème, le Client peut contacter le Responsable de traitement à l'adresse électronique indiquée à l’article 8.3 « Responsable de traitement »</li>
<li>Il peut supprimer leur compte en écrivant à l’adresse électronique indiquée à l’article 8.3 « Responsable de traitement »</li>
<li>Il peut exercer son droit d’accès pour connaître les données personnelles le concernant en écrivant à l’adresse indiquée à l’article 9.3 « Responsable de traitement »</li>
<li>Si les données à caractère personnel détenues par le Prestataire sont inexactes, il peut demander la mise à jour des informations en écrivant à l’adresse indiquée à l’article 8.3 « Responsable de traitement »</li>
<li>Il peut demander la suppression de ses données à caractère personnel, conformément aux lois applicables en matière de protection des données en écrivant à l’adresse indiquée à l’article 8.3 « Responsable de traitement »</li>
<li>Il peut également solliciter la portabilité des données détenues par le Prestataire vers un autre prestataire</li>
<li>Enfin, il peut s’opposer au traitement de leurs données par le Prestataire</li>
</ul>
Ces droits, dès lors qu’ils ne s’opposent pas à la finalité du traitement, peuvent être exercés en adressant une demande par courrier ou par E-mail au Responsable de traitement dont les coordonnées sont indiquées ci-dessus.<br/>
Le Responsable de traitement doit apporter une réponse dans un délai maximum d’un mois.<br/>
En cas de refus de faire droit à la demande du Client, celui-ci doit être motivé.<br/>
Le Client est informé qu’en cas de refus, il peut introduire une réclamation auprès de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir une autorité judiciaire.<br/>
Le Client peut être invité à cocher une case au titre de laquelle il accepte de recevoir des mails à caractère informatifs et publicitaires de la part du Prestataire. Il aura toujours la possibilité de retirer son accord à tout moment en contactant le Prestataire (coordonnées ci-dessus) ou en suivant le lien de désabonnement.<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 9 - Engagement</strong>
<p style={styles.bodyStyle}>
Le Client s’engage à :<br/>
<ul>
<li>Respecter l’intégralités de ces CGU-CGV sans réserve de sa part, ainsi que de prendre connaissance de ces dernières lors de leur mise à jour ;</li>
<li>Arrêter son usage de tout Service dans le cas d’un désaccord avec les modifications des CGU-CGV ;</li>
<li>Ne pas utiliser les Services fournis par le Prestataire à des fins non prévues ou illégales ;</li>
<li>Ne pas chercher à contourner l’utilisation des Services fournis par le Prestataire ;</li>
<li>Ne pas copier ou modifier l’application ni aucunes de nos marques déposées ;</li>
<li>Ne pas tenter d’extraire le code source de l’application ;</li>
<li>Ne pas tenter de traduire l’application ou d’en faire des versions dérivées ;</li>
<li>Effectuer toutes les mises à jour de l’application lorsqu’elles lui sont proposées ;</li>
<li>Arrêter tout usage de l’application si le Prestataire décidait de mettre fin à ses Services ;</li>
</ul>
</p>

<strong style={styles.headerStyle}>ARTICLE 10 – Prestataires tiers de Services</strong>
<p style={styles.bodyStyle}>
L’application STUDE fait appel à des Services tiers, dont les Prestataires possèdent leurs propres Conditions d’Utilisations. En consentant aux présentes CGU-CGV, le Client consent donc également à ces Conditions :<br/>
<ul>
<li><a style={styles.linkStyle} href='https://developers.google.com/admob/terms'>AdMob</a></li>
<li><a style={styles.linkStyle} href='https://docs.bugsnag.com/legal/terms-of-service'>Bugsnag</a></li>
<li><a style={styles.linkStyle} href='https://www.revenuecat.com/terms'>RevenueCat</a></li>
</ul>
</p>

<strong style={styles.headerStyle}>ARTICLE 11 – Responsabilité</strong>
<p style={styles.bodyStyle}>
<ul>
<li>L’application STUDE collecte et traite de manière sécurisée les données que le Client a consenti de partager. En revanche, il relève de la responsabilité du Client de sécuriser l’accès à son téléphone et à l’application. Le Prestataire recommande donc de ne pas effectuer de manœuvre visant à réduire les limitations logicielles ainsi que la sécurité des appareils (‘’jailbreak’’ et ‘’root’’). Cela pourrait rendre l’appareil vulnérable à des logiciels malicieux et compromettre la sécurité de vos données, ainsi que le fonctionnement général de l’application STUDE.</li>
<li>Le Prestataire ne prendra pas la responsabilité d’événements relatifs au fonctionnement général d’un appareil mobile : dépassement de forfait de données Internet, décharge de la batterie de l’appareil…</li>
</ul>
</p>

<strong style={styles.headerStyle}>ARTICLE 12 - Propriété intellectuelle</strong>
<p style={styles.bodyStyle}>
Le contenu du site https://www.stude.app est la propriété du Prestataire et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.<br/>
Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 13 - Droit applicable - Langue</strong>
<p style={styles.bodyStyle}>
Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit français.<br/>
Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.<br/>

</p>

<strong style={styles.headerStyle}>ARTICLE 14 - Litiges</strong>
<p style={styles.bodyStyle}>
Pour toute réclamation merci de contacter le service clientèle à l’adresse postale ou mail du Prestataire indiquée à l’ARTICLE 1 des présentes CGV.<br/>
Le Client est informé qu'il peut en tout état de cause recourir à une médiation conventionnelle, auprès des instances de médiation sectorielles existantes ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas de contestation. <br/>
En l’espèce, le médiateur désigné est<br/>
_______________<br/>
_______________<br/>
_______________<br/>
E-mail : _______________. <br/>
<br/>
Le Client est également informé qu’il peut, également recourir à la plateforme de Règlement en Ligne des Litige (RLL) :https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show<br/>
Tous les litiges auxquels les opérations d'achat et de vente conclues en application des présentes CGV et qui n’auraient pas fait l’objet d’un règlement amiable entre le vendeur ou par médiation, seront soumis aux tribunaux compétents dans les conditions de droit commun.<br/>

</p>


    </div>

}

export default TermsOfUse;

const styles = {
    container: {
        padding: 20,
        paddingLeft: 40, 
        paddingRight: 40
    },
    headerStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 35,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    bodyStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    linkStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    }
}