import './Panel.css'
import React, {useState} from 'react'

const Panel = ({children, state, set_state}) => {

    return (
        <div className='PanelContainer'>
            {children}
        </div>
    )
}

export default Panel
