import React from 'react';


const PrivacyPolicy = () => {
    return <div style={styles.container}>
            <strong style={styles.headerStyle}>Politique de confidentialité</strong> <p style={styles.bodyStyle}>
STUDE s’engage à ce que les traitements de données personnelles effectués sur son site et son application mobile soient conforme au Règlement général sur la protection des données (RGPD) et à la Loi Informatique et Libertés.
<br/><br/>
L’application est dite Freemium. Elle est mise à disposition par le Prestataire gratuitement sur les plateformes de téléchargement, à l’exception des contenus additionnels qui peuvent être achetés par le Client, à sa guise. 
<br/><br/>
En utilisant ce service, le Client consent à la collecte et au traitement des données en relation avec cette charte. Les termes utilisés dans cette charte ont la même portée et définition que ceux employés dans les Conditions Générales de Vente, sauf exception spécifiée explicitement dans la charte. 
<br/><br/>
</p> 

<strong style={styles.headerStyle}>I. Identité du responsable de traitement et contact</strong>
<p style={styles.bodyStyle}>
Le responsable de traitement est le Prestataire, Monsieur Ali Hawari.<br/><br/>
Mail : <a style={styles.linkStyle} href='mailto:stude.data@gmail.com'>stude.data@gmail.com</a><br/>
Adresse : 11 rue du Sudel, 68500 GUEBWILLER<br/>
Téléphone : +33 7 82 06 43 77<br/>
</p>

<strong style={styles.headerStyle}>II. Exercice des droits</strong>
<p style={styles.bodyStyle}>
Le chapitre III du RGPD offre au Client l’ensemble des droits suivants :
<ul>
<li>Droit à l’information au traitement.</li>
<li>Droit d’accès aux données.</li>
<li>Droit de rectification des données.</li>
<li>Droit à la limitation des données.</li>
<li>Droit à l’effacement des données.</li>
<li>Droit d’introduire une réclamation auprès d’une autorité de protection des données : en France, la CNIL.</li>
<li>Droit à la portabilité des données.</li>
<li>Droit au retrait du consentement.</li>
<li>Droit d’opposition au traitement de données.</li>
</ul>
</p>

<strong style={styles.headerStyle}>III. Base juridique du traitement</strong>
<p style={styles.bodyStyle}>
STUDE fonde son traitement de données à caractère personnel sur les articles 6.a et 6.b et 6.f du RGPD. 
</p>

<strong style={styles.headerStyle}>IV. Finalités de traitement</strong>
<p style={styles.bodyStyle}>
Pour garantir une meilleure expérience lors de l’utilisation de notre Service, il peut être demandé au Client de fournir diverses données à caractère personnel. Notre Service en ligne limite cependant la collecte au strict nécessaire, conformément au principe de minimisation des données. 
<br/><br/>
Les informations sont collectées en vue de la réalisation de quatre finalités :<br/><br/>
<ul>
<li>Pour l’amélioration de la qualité du service de STUDE : Des données concernant les bugs peuvent être recueillies, si le Client y consent, dans le but d’améliorer notre Service. Ces bugs donnent lieu à un rapport qui peut inclure des informations sur son appareil tel que l’adresse Internet Protocol (IP), nom de l’appareil, système d’exploitation, configuration de l’application lors de l’utilisation du Service, la date et l’heure de l’utilisation litigieuse et les statistiques. Ce rapport sera conduit à travers l’utilisation du service de notre partenaire, BugSnag. La base de traitement spécifique à ce traitement est l’article 6.a du RGPD.
<br/><br/>
De plus, des données personnelles peuvent être collectées, si le Client y consent, par notre partenaire AdMob afin de lui proposer des publicités plus pertinentes. La base de traitement spécifique à ce traitement est double, l’article 6.a et l’article 6.f du RGPD.</li>
<br/>
<li>Pour la création d’un compte STUDE : Nécessaire à l’utilisation de notre application, notre Service collectera l’adresse mail du Client ainsi que son identifiant, qui peut être un pseudonyme. La base de traitement spécifique à ce traitement est l’article 6.b du RGPD. </li>
<br/>
<li>Pour l’abonnement à notre Service STUDE+ et l’amélioration de nos algorithmes STUDE : L’abonnement à notre Service STUDE+ permet au Client de sauvegarder ses données STUDE dans un Cloud. Ces données sont conservées au sein de nos serveurs. Ces données peuvent être analysées dans le but d’améliorer notre algorithme et notre prestation. La base de traitement spécifique à ce traitement est double, l’article 6.a et 6.b du RGPD.</li>
<br/>
<li>Pour garantir la sécurité des paiements : Afin de garantir le plus haut degré de sécurité lors des transactions bancaires sur les Stores (App Store et Google Play Store), d’autres données peuvent être collectées, à savoir, le nom utilisé sur la carte de crédit, le pays de résidence ainsi qu’une adresse de facturation. Ces données resteront néanmoins réservées au système interne des sus-mentionnés Stores ainsi que de RevenueCat, notre partenaire de gestion des abonnements. La base de traitement spécifique à ce traitement est l’article 6.b du RGPD. </li>

</ul>

STUDE fait appel à des services tiers qui peuvent collecter des données à caractère personnel. 

Retrouvez ci-dessous les politiques de protection des données de chacun de nos partenaires : 
<ul>
<li style={styles.linkStyle}><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer" style={styles.linkStyle}>Google Play Services</a></li>
<li style={styles.linkStyle}><a href="https://developer.apple.com/app-store/app-privacy-details/" target="_blank" rel="noopener noreferrer" style={styles.linkStyle}>App Store</a></li>
<li style={styles.linkStyle}><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer" style={styles.linkStyle}>Admob</a></li>
<li style={styles.linkStyle}><a href="https://docs.bugsnag.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer" style={styles.linkStyle}>Bugsnag</a></li>
<li style={styles.linkStyle}><a href="https://www.revenuecat.com/privacy/#:~:text=We%20will%20disclose%20your%20personal,terms%20of%20this%20Privacy%20Policy." target="_blank" rel="noopener noreferrer" style={styles.linkStyle}>RevenueCat</a></li>
</ul>
</p>

<strong style={styles.headerStyle}>V. Destinataire des données collectées</strong>
<p style={styles.bodyStyle}>
Auront accès à vos données à caractère personnel notre personnel, nos sous-traitants, nos hébergeurs ainsi nos prestataires de service de paiement.
<br/><br/>
Peuvent également être destinataires de vos données à caractère personnel les organismes publics, exclusivement pour répondre à nos obligations légales, les auxiliaires de justice, les officiers ministériels et les organismes chargés d’effectuer le recouvrement des créances.
</p>

<strong style={styles.headerStyle}>VI. Cookies</strong>
<p style={styles.bodyStyle}>
Un cookie est une suite d’informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée et le renverra au serveur web chaque fois que vous vous y connecterez de nouveau. 
<br/><br/>
STUDE n’utilise pas de cookies. Cependant, notre Service peut utiliser des cookies tiers ainsi que des serveurs utilisant des cookies strictement nécessaires à l’utilisation et au fonctionnement du site, ou des cookies statistiques. Le Client a le choix d’y consentir ou d’y refuser.
<br/>
Cependant, il est à noter que si le Client refuse, il se peut que son usage du Service soit limité. 
</p>

<strong style={styles.headerStyle}>VII. Sous-traitance</strong>
<p style={styles.bodyStyle}>
Il se peut que notre Service emploie des sous-traitants pour assister à l’analyse des données, faciliter le Service ou encore, l’améliorer ou le mettre à disposition. 
<br/><br/>
Dans ce cas, les sous-traitants seront soumis au RGPD et devront mettre en œuvre toutes les mesures techniques et organisationnelles nécessaires à un traitement de données conforme et sécurisé. 
<br/><br/>
Le traitement par les sous-traitants des données à caractère personnel du Client est, dans l’éventualité où il est fait appel à eux, limité aux finalités visées précédemment et uniquement dans le cadre de sa relation avec le Service. Ils sont soumis à une obligation de confidentialité.
</p>

<strong style={styles.headerStyle}>VIII. Mesures de sécurité</strong>
<p style={styles.bodyStyle}>
STUDE s’engage à fournir ses meilleurs efforts en mettant en œuvre les mesures organisationnelles et techniques nécessaires à la protection des données à caractère personnel du Client, plus particulièrement contre le risque de perte, d’altération ou d’accès non autorisé à ses données. 
<br/><br/>
Cependant, aucune méthode de transmission sur Internet ou de stockage n’est entièrement fiable et il est alors impossible de faire de cette protection une obligation de résultat. 
</p>

<strong style={styles.headerStyle}>IX. Durée de conservation des données</strong>
<p style={styles.bodyStyle}>
Les données sont conservées pendant toute la durée de la relation contractuelle et/ou de l’utilisation de l’application, plus un délai de 1 an après la suppression du compte STUDE par le Client. 
</p>

<strong style={styles.headerStyle}>X. Modification</strong>
<p style={styles.bodyStyle}>
Notre Service se réserve le droit de modifier à tout moment la présente charte afin de répondre aux réglementations en vigueur. 
</p>

<strong style={styles.headerStyle}>XI. Entrée en vigueur</strong>
<p style={styles.bodyStyle}>
La présente charte est entrée en vigueur le 30/09/2022
</p>


    </div>
}

export default PrivacyPolicy;

const styles = {
    container: {
        padding: 20,
        paddingLeft: 40, 
        paddingRight: 40
    },
    headerStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 35,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    bodyStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    linkStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    }
}
