import remoteAPI from "../api/remoteAPI";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

export const authenticate = async ({email, password, state, set_state}) => {
    const cookies = new Cookies()
    let regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    let valid_email = regex.test(email) && email.includes(' ') == false
    let password_regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/)
    let valid_password = password_regex.test(password)
    if (valid_email && valid_password) {
        if (state.authform.type == 'signin') {
            //console.log('SIGNIN : ', email, password)
            const response = await remoteAPI.post('/auth/weblogin', {username: email, password: password});
            //console.log(response.data)
            if (response.data.uuid != null) {
                //const cookies = new Cookies()
                cookies.set('credentials', 
                    {email, password, uuid: response.data.uuid, username: response.data.name, next_billing_date: response.data.next_billing_date*1000, subscribed: response.data.subscribed},
                    {path: '/', expires: new Date(response.data.expires)}
                    )
                set_state({...state, user: {...state.user, email, password, authenticated: true, uuid: response.data.uuid, username: response.data.name, next_billing_date: new Date(response.data.next_billing_date*1000), subscribed: response.data.subscribed}, authform: {...state.authform, error_message: ''}})
                
            } else {
                let user = {...state.user, email: '', password: '', authenticated: false, username: '', uuid: null}
                if (response.data.error == 'Unauthorized') {
                    set_state({...state, user, authform: {...state.authform, error_message: 'Wrong email and/or password.'}})
                } else {
                    set_state({...state, user, authform: {...state.authform, error_message: 'An unknown error has occured.'}})
                }
            }
        } else if (state.authform.type == 'signup') {
            //console.log('SIGNUP : ', state.authform.email, state.authform.password)
            const response = await remoteAPI.post('/auth/signup', {email, password});
            //console.log(response.data);
            if (response.data.ok == true) {
                set_state({...state, authform: {...state.authform, error_message: '', type: 'signin'}})
            } else {
                if (response.data.reason == 'conflict') {
                    set_state({...state, authform: {...state.authform, error_message: 'This user already exists.'}})
                } else if (response.data.reason == 'unknown') {
                    set_state({...state, authform: {...state.authform, error_message: 'An unknown error has occured.'}})
                }
            }
        }
    } else {
        if (!valid_email) {
            set_state({...state, authform: {...state.authform, error_message: 'Invalid email format.'}})
        } else if (!valid_password) {
            set_state({...state, authform: {...state.authform, error_message: 'Password must be > 8 characters, include at least one capital letter, one number and one special character.'}})
        }
    }
}


export const signin = async ({email, password, state, set_state}) => {
    const cookies = new Cookies()
    let regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    let valid_email = regex.test(email) && email.includes(' ') == false
    let password_regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/)
    let valid_password = password_regex.test(password)
    if (valid_email && valid_password) {
            //console.log('SIGNIN : ', email, password)
            try{
                set_state({...state, authform: {...state.authform, loading: true}})

                const response = await remoteAPI.post('/auth/weblogin', {username: email, password: password});
                if (response.data.uuid != null) {
                    //const cookies = new Cookies()
                    cookies.set('credentials', 
                        {email, password, uuid: response.data.uuid, username: response.data.name, next_billing_date: response.data.next_billing_date*1000, subscribed: response.data.subscribed},
                        {path: '/', expires: new Date(response.data.expires)}
                        )
                    set_state({...state, user: {...state.user, email, password, authenticated: true, uuid: response.data.uuid, username: response.data.name, next_billing_date: new Date(response.data.next_billing_date*1000), subscribed: response.data.subscribed}, authform: {...state.authform, error_message: '', loading: false}})
                    
                } else {
                    
                    let user = {...state.user, email: '', password: '', authenticated: false, username: '', uuid: null}
                    if (response.data.error == 'Unauthorized') {
                        set_state({...state, user, authform: {...state.authform, error_message: 'Wrong email and/or password.', loading: false}})
                    } else {
                        set_state({...state, user, authform: {...state.authform, error_message: 'An unknown error has occured.', loading: false}})
                    }
                }
            } catch (err) {
                //console.log('ERR WEBLOGIN')
                //console.log(err)
            }
    } else {
        if (!valid_email) {
            set_state({...state, authform: {...state.authform, error_message: 'Invalid email format.', loading: false}})
        } else if (!valid_password) {
            set_state({...state, authform: {...state.authform, error_message: 'Password must be > 8 characters, include at least one capital letter, one number and one special character.', loading: false}})
        }
    }
}

export const signup = async({username, email, password, confirmPassword, user_newsletter, state, set_state}) => {
    const cookies = new Cookies()
    let regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    let valid_email = regex.test(email) && email.includes(' ') == false
    let password_regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/)
    let valid_password = password_regex.test(password)
    let valid_username = username.trim().length > 0
    let valid_confirmPassword = (password == confirmPassword)
    if (valid_email && valid_password && valid_username && valid_confirmPassword) {
            //console.log('SIGNUP : ', email, password)
            try{
            const response = await remoteAPI.post('/auth/register', {name: username, email: email, password: password, confirmPassword: confirmPassword, user_newsletter: user_newsletter});
            //console.log(response.data)
            if (response.data.success) {
                toast.success('A confirmation email was sent to ' + email + '!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                  })
                set_state({...state, authform: {...state.authform, error_message: '', type: 'signin'}})
            } 
            } catch(err) {
                if (err.response.data.error == 'conflict') {
                    set_state({...state, authform: {...state.authform, error_message: 'This user already exists.'}})
                } else {
                    set_state({...state, authform: {...state.authform, error_message: 'An unknown error has occured.'}})
                }
            }
            
            //else {
            //    if (response.data.error == 'conflict') {
            //        set_state({...state, authform: {...state.authform, error_message: 'This user already exists.'}})
            //    } else if (response.data.reason == 'unknown') {
            //        set_state({...state, authform: {...state.authform, error_message: 'An unknown error has occured.'}})
            //    }
            //}
    } else {
        if (!valid_username) {
            set_state({...state, authform: {...state.authform, error_message: 'Invalid username.'}})
        } else if (!valid_email) {
            set_state({...state, authform: {...state.authform, error_message: 'Invalid email format.'}})
        } else if (!valid_password) {
            set_state({...state, authform: {...state.authform, error_message: 'Password must be > 8 characters, include at least one capital letter, one number and one special character.'}})
        } else if (!valid_confirmPassword) {
            set_state({...state, authform: {...state.authform, error_message: "Passwords don't match."}})
        }
    }
}

export const logout = ({state, set_state}) => {
    const cookies = new Cookies()
    cookies.remove('credentials')
    set_state({...state, user: {email: '',
                                password: '',
                                uuid: null,
                                authenticated: false,
                                subscribed: null,
                                uuid: null,
                                next_billing_date: null
                                }
})
}

export const checkSubscriptions = async({state, set_state}) => {
    const response = await remoteAPI.post('/subscriptions/check', {uuid: state.user.uuid})
        let subscribed = response.data.subscribed
        let current_period_end = response.data.current_period_end
        set_state({...state, user: {...state.user, subscribed, next_billing_date: (new Date(current_period_end*1000))}})
}