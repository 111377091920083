import './css/Success.css'
import React, {useEffect, useState} from 'react'
import Panel from '../Panel'
import {FiCheckCircle} from 'react-icons/fi'
import {useParams} from 'react-router-dom'
import NotFound from './NotFound'


const PaymentSuccess = ({state, set_state}) => {
    const session_id_param = useParams().session_id
    const session_id = session_id_param.split('=') != null ? session_id_param.split('=')[1] : null

    
    return <Panel>
        {
            session_id != null && session_id != ''
            ?
                <div style={{}}>
                    <div style={{display: 'flex', flexDirection:'row', alignItems:'center', marginTop: 40, justifyContent:'center'}}>
                        <FiCheckCircle size={45} color='green' />
                        <div style={{fontSize: 40, color: 'white', fontFamily:'Inter', textAlign:'center', fontWeight: '200', marginLeft: 20}}>
                            Success!
                        </div>
                    </div>
                    <div style={{fontSize: 30, color: 'white', fontFamily:'Inter', flex: 1, textAlign:'center', fontWeight: '200', marginTop: 20}}>
                        Your account was successfully upgraded to Stude +
                    </div>
                    <div style={{fontSize: 30, color: 'white', fontFamily:'Inter', flex: 1, textAlign:'center', fontWeight: '200', marginTop: 20}}>
                        An e-mail containing your receipt was sent! 
                    </div>
                </div>
            : <NotFound />
        }
        
    </Panel>
}


export default PaymentSuccess;

const styles = {
    usernameContainer: {
        width: 600,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20
    },
    planContainer: {
        width: 300,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20,
        flexDirection: 'column',
        padding: 20
    },
    managementContainer: {
        width: 300,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20,
        padding: 20,
        flexDirection: 'column'
    },
    mainContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    title: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 25,
        color: '#CDE1FB',
        textAlign: 'center',
        width: '100%',
        marginBottom: 10
    },
    planFieldContainer: {
        minHeight: 50,
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 20,
        color: '#CDE1FB',
        textAlign: 'left',
        width: '100%',
        flexDirection:'row',
        display:'flex',
        marginBottom: 2,
        marginTop: 8,
        alignItems:'center'
    },
    logoutContainer: {
        minHeight: 50,
        minWidth: 50,
        display: 'flex',
        marginTop: 20
    }, 
    logoutButton: {
        height: 50,
        width: 50,
        //backgroundColor: '#18212A',
        borderWidth: 0,
        borderRadius: 15,
        marginLeft: 'auto',
        marginRight: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}
