import './css/AuthForm.css'
import React, {useEffect, useState} from 'react'
import remoteAPI from '../api/remoteAPI'
import {FiMail, FiKey} from 'react-icons/fi'
import {FaRegUser} from 'react-icons/fa'
import { authenticate, signin, signup } from '../hooks/hooks'
import {toast} from 'react-toastify'

const AuthForm = ({state, set_state}) => {
    const [user_consent, set_user_consent] = useState(false)
    const [user_newsletter, set_user_newsletter] = useState(false)
    const onLinkClick = () => {
        if (state.authform.type == 'signin') {
            set_state({...state, authform: {...state.authform, type: 'signup'}})
        } else if (state.authform.type == 'signup') {
            set_state({...state, authform: {...state.authform, type: 'signin'}})
        }
    }
    
    

    const handleButtonClick = () => {
        let email = state.authform.email != null ? state.authform.email : ''
        let password = state.authform.password != null ? state.authform.password : ''
        if (state.authform.type == 'signin') {
            signin({email, password, state, set_state})
        } else {
            if (user_consent != false) {
                let username = state.authform.username != null ? state.authform.username : ''
                let confirmPassword = state.authform.confirmPassword != null ? state.authform.confirmPassword : ''
                signup({username, email, password, confirmPassword, user_newsletter, state, set_state})
            } else {
                toast.error('You must accept our terms and conditions and privacy policy.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                  })
            }
        }
        
        
    }

    const handleForgotPassword = async () => {
        let email = state.authform.email
        let email_regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
        let valid_email = email_regex.test(email) && email.includes(' ') == false
        if (valid_email) {
            const response = await remoteAPI.post('/auth/forgot-password', 
                    {
                     email
                    });
            if (response.data.success.includes('processed')) {
                toast.success('A reset password link was sent to ' + email + '!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                  })
            }
        } else {
            toast.error('Invalid email format.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
              })
        }
    }

    

    return (
            <div className='authformcontainer'>
                <div className='formcontainer' style={{height: 'auto', display: 'flex', flexDirection:'column'}}>
                    <h1 style={{fontWeight: 300, fontSize: 35, fontFamily:'Inter', color:'#CDE1FB', textAlign:'center'}}>{state.authform.type == 'signin' ? 'Sign in' : 'Sign up'}</h1>
                    {
                        state.authform.type != 'signup' ? null :
                        <>
                            <div style={{ display:'flex'}}>
                            <FaRegUser size={35} style={{marginInlineEnd: 15, marginInlineStart: 20}} color='#CDE1FB'/>
                                <input className='field username'
                                    itemID="username"
                                    placeholder='Username'
                                    type='text'
                                    onChange={(props) => {set_state({...state, authform: {...state.authform, username: props.target.value }})}}
                                    onKeyPress={(e) => {
                                        if (e.nativeEvent.key == 'Enter') {
                                            handleButtonClick()
                                        }
                                    }}
                                /> 
                            </div>
                            <div style={{height: 25}}/>
                        </>
                    }
                    <div style={{ display:'flex'}}>
                        <FiMail size={35} style={{marginInlineEnd: 15, marginInlineStart: 20}} color='#CDE1FB'/>
                        <input className='field email'
                            itemID="email"
                            placeholder='Email'
                            type='text'
                            onChange={(props) => {set_state({...state, authform: {...state.authform, email: props.target.value }})}}
                            onKeyPress={(e) => {
                                if (e.nativeEvent.key == 'Enter') {
                                    handleButtonClick()
                                }
                            }}
                        />
                    </div>
                    <div style={{height: 35}}/>
                    <div style={{ display:'flex'}}>
                        <FiKey size={35} style={{marginInlineEnd: 15, marginInlineStart: 20}} color='#CDE1FB'/>
                        <input className='field password'
                            itemID='password'
                            placeholder='Password'
                            type='password'
                            onChange={(props) => {set_state({...state, authform: {...state.authform, password: props.target.value }})}}
                            onKeyPress={(e) => {
                                if (e.nativeEvent.key == 'Enter') {
                                    handleButtonClick()
                                }
                            }}
                        />
                    </div>
                    <div style={{height: 35}}/>
                    {
                        state.authform.type != 'signup' ? null :
                        <>
                            <div style={{ display:'flex'}}>
                            <FiKey size={35} style={{marginInlineEnd: 15, marginInlineStart: 20}} color='rgba(255,255,255,0)'/>
                                <input className='field password'
                                    itemID="password"
                                    placeholder='Confirm password'
                                    type='password'
                                    onChange={(props) => {set_state({...state, authform: {...state.authform, confirmPassword: props.target.value }})}}
                                    onKeyPress={(e) => {
                                        if (e.nativeEvent.key == 'Enter') {
                                            handleButtonClick()
                                        }
                                    }}
                                /> 
                            </div>
                        </>
                    }
                    <div className='error_message'>
                        {state.authform.error_message}
                    </div>
                    {state.authform.type != 'signin' ? null : 
                        <div style={{ flexDirection: 'column', display: 'flex'}}>
                            <a className='linktoform' onClick={handleForgotPassword}>
                                Forgot your password?
                            </a>
                        </div>
                    }
                    <div style={{ flexDirection: 'column', marginTop: 20, display: 'flex'}}>
                        <a className='linktoform' onClick={onLinkClick}>
                            {state.authform.type == 'signin' 
                            ? "You don't have an account yet?" + '\n' + "Tap here to sign up." 
                            : "You already have an account?" + '\n' + "Tap here to sign in."}
                        </a>
                    </div>
                    {
                        state.authform.type == 'signup' 
                        ? 
                        <>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems:'center'}}>
                            <input 
                                type="checkbox" 
                                style={{height: 40, width: 40, accentColor:'#226D68'}} 
                                checked={user_consent}
                                onChange={() => {
                                    set_user_consent(!user_consent)
                                }}
                                />
                            <div style={{color: 'white', fontFamily:'Inter', fontWeight: '200', textAlign:'center'}}>
                                I agree to Stude's <a style={styles.linkStyle}
                                    href='/terms-of-use'
                                >
                                    terms and conditions
                                </a> and <a style={styles.linkStyle}
                                    href='/privacy-policy'
                                >
                                    privacy policy
                                </a>.
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems:'center'}}>
                            <input 
                                type="checkbox" 
                                style={{height: 60, width: 60, accentColor:'#226D68'}} 
                                checked={user_newsletter}
                                onChange={() => {
                                    set_user_newsletter(!user_newsletter)
                                }}
                                />
                            <div style={{color: 'white', fontFamily:'Inter', fontWeight: '200', textAlign:'center'}}>
                                Send me information about new products and services, deals or recommandations by mail
                            </div>
                        </div>
                        </>
                        : null
                    }
                    <button itemID='formbutton' className='formbutton' onClick={handleButtonClick}>
                        {state.authform.type == 'signin' ? 'Sign in' : 'Sign up'}
                    </button>
                </div>
            </div>
    )
}


export default AuthForm;

const styles = {
    linkStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 15,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    }
}