import React, { useEffect, useState } from 'react';
import licenses from '../assets/licenses.txt'

const Licenses =  () => {
    const [licenses_text, set_licenses_text] = useState('')
    useEffect(() => {
            fetch(licenses).then((res) => {
                return res.text()
            }).then((text) => {
                set_licenses_text(text)
            })
    },[])
    
    return <div style={styles.container}>
        <div style={styles.bodyStyle}>
            {licenses_text}
        </div>
    </div>
}

export default Licenses;

const styles = {
    container: {
        padding: 20,
        paddingLeft: 40, 
        paddingRight: 40
    },
    headerStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 35,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    bodyStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    linkStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    }
}
