import React from 'react';


const Acknowledgements =  () => {

    
    return <div style={styles.container}>
        <div style={styles.bodyStyle}>
            <ul>
                <li style={styles.linkStyle}>
                    Thanks to Affan, for his wise advice when it comes to color theory :)
                    {"\n"}
                    <a href='https://afn.pages.dev/' style={styles.linkStyle}>Check out his website</a>
                </li>
            </ul>
        </div>
    </div>
}

export default Acknowledgements;

const styles = {
    container: {
        padding: 20,
        paddingLeft: 40, 
        paddingRight: 40
    },
    headerStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 35,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    bodyStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    linkStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    }
}
