import './css/UserPanel.css'
import React, {useEffect, useState} from 'react'
import Panel from '../Panel'
import AuthForm from './AuthForm'
import Subscribe from '../Subscribe'
import { logout } from '../hooks/hooks'
import RotateLoader from 'react-spinners/PuffLoader'

import {IoSyncOutline} from 'react-icons/io5'
import {FiDatabase, FiCheck, FiX, FiLogOut} from 'react-icons/fi'
import { ADS_GLOBAL_ENABLED } from '../hooks/constants'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import remoteAPI from '../api/remoteAPI'
import {toast} from 'react-toastify'



const UserPanel = ({state, set_state}) => {

    const handleLogOutButton = () => {
        logout({state, set_state})   
    }
    

    const handleDeleteButton = () => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete your account ?',
            buttons: [
              {
                label: 'Yes',
                onClick: async () => {
                    //const response = await remoteAPI.post('/delete-account', {uuid: state.user.uuid, email: state.user.email});
                    const response = await remoteAPI.post('/delete-account', {uuid: state.user.uuid, email: state.user.email});
                    console.log(response.data, state.user.uuid, state.user.email)
                    if (response.data.success == true) {
                        toast.success('Your account was successfully deleted!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000
                        })
                        logout({state, set_state})
                    } else {
                        toast.error('An error occured', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000
                        })
                        logout({state, set_state})
                    }
                }
              },
              {
                label: 'No',
                onClick: () => {

                }
              }
            ]
          });
    }

    const Field = ({name='', enabled=false}) => {
        return <div style={styles.planFieldContainer}>
                {name}
                <div style={{marginLeft:'auto', alignSelf:'center'}}>
                    {
                        enabled == true ?
                        <FiCheck style={{color:'rgb(0, 207, 0)'}} size={30}/> :
                        <FiX style={{color:'red'}} size={30}/>
                    }
                </div>
        </div>
    }

    const Separator = () => {
        return <div style={{width:'100%', height: 0.5, backgroundColor:'#3A4958'}}/>
    }
    
    return <Panel>
        {
            state.authform.loading != null && state.authform.loading  == true ? 
            <div style={{display: 'flex', width: '100%', height: 100, alignItems:'center', justifyContent:'center'}}>
                <RotateLoader 
                    color="white"
                /> 
            </div>
            :
            (
                state.user.authenticated == false 
                ? <AuthForm state={state} set_state={set_state}/>
                : 
                <div className='userPanelContainer'>
                <div style={styles.usernameContainer}>
                    <div className='usernameText'>Welcome {state.user.username}!</div>
                </div>
                
                <div style={styles.mainContainer}>
                    <div style={styles.planContainer}>
                        <div style={styles.title}>
                            Current plan: Stude {state.user.subscribed ? '+' : 'Free'}
                        </div>
                        <Field name='Online backup' enabled={state.user.subscribed}/>
                        <Separator />
                        <Field name='Multi-devices' enabled={state.user.subscribed}/>
                        {ADS_GLOBAL_ENABLED == true ?
                        <>
                            <Separator />
                            <Field name='No ads' enabled={state.user.subscribed}/>
                        </>
                        : null
                        }
                    </div>

                    <div style={styles.managementContainer}>
                        { state.user.subscribed 
                        ? <div style={{fontSize: 20, color: 'white', fontWeight: '200', fontFamily:'Inter', width: '100%', wordWrap:'break-word', textAlign:'center', flex: 2, display: 'flex', alignItems:'center', justifyContent:'center'}}>
                            Next billing: {state.user.next_billing_date != null ? state.user.next_billing_date.toISOString().split('T')[0] : ''}
                         </div>
                         : 
                         <div style={{fontSize: 20, color: 'white', fontWeight: '200', fontFamily:'Inter', width: '100%', wordWrap:'break-word', textAlign:'center', flex: 1}}>
                            Subscribe to Stude + for a better studying experience!
                         </div>
                        } 
                        {
                            //<Subscribe state={state} set_state={set_state}/>
                        }
                    </div>
                    
                </div>
                <div style={styles.buttonsContainer}>
                    <div style={styles.logoutContainer}>
                            <button style={styles.logoutButton} onClick={handleLogOutButton} className='logoutButton'>
                                <FiLogOut size={styles.logoutButton.height - 15} color='white'/>
                            </button>
                    </div>
                    <div style={styles.deleteContainer}>
                            <button style={styles.deleteButton} onClick={handleDeleteButton} className='logoutButton'>
                                Delete my account
                            </button>
                    </div>
                </div>
                
                </div>
            )
        }
    </Panel>
}


export default UserPanel;

const styles = {
    usernameContainer: {
        width: 600,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20
    },
    planContainer: {
        width: 300,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20,
        flexDirection: 'column',
        padding: 20,
    },
    managementContainer: {
        width: 300,
        backgroundColor: '#18212A',
        display: 'flex',
        minHeight: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 25,
        marginTop: 20,
        padding: 20,
        flexDirection: 'column'
    },
    mainContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    title: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 25,
        color: '#CDE1FB',
        textAlign: 'center',
        width: '100%',
        marginBottom: 10
    },
    planFieldContainer: {
        minHeight: 50,
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 20,
        color: '#CDE1FB',
        textAlign: 'left',
        width: '100%',
        flexDirection:'row',
        display:'flex',
        marginBottom: 2,
        marginTop: 8,
        alignItems:'center'
    },
    logoutContainer: {
        minHeight: 50,
        minWidth: 50,
        display: 'flex',
        marginTop: 20
    }, 
    logoutButton: {
        height: 50,
        width: 50,
        //backgroundColor: '#18212A',
        borderWidth: 0,
        borderRadius: 15,
        //marginLeft: 'auto',
       // marginRight: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    deleteContainer: {
        minHeight: 50,
        minWidth: 50,
        display: 'flex',
        marginTop: 20,
    }, 
    deleteButton: {
        height: 50,
        width: 'auto',
        padding: 10,
        //backgroundColor: '#18212A',
        borderWidth: 0,
        borderRadius: 15,
       // marginLeft: 'auto',
        //marginRight: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'red',
        fontFamily:'Inter',
        fontWeight: '200',
        
    },
    buttonsContainer: {
        width: 200,
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: '15%'
    }
}
