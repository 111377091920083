import './css/Success.css'
import React, {useEffect, useState} from 'react'
import Panel from '../Panel'


import {FiXCircle} from 'react-icons/fi'


const NotFound = ({state, set_state}) => {

    return <Panel>
        <div style={{display: 'flex', flexDirection:'row', alignItems:'center', marginTop: 40, justifyContent:'center'}}>
            <FiXCircle size={45} color='red' />
            <div style={{fontSize: 40, color: 'white', fontFamily:'Inter', textAlign:'center', fontWeight: '200', marginLeft: 10}}>
                404 Error
            </div>
        </div>
        <div style={{fontSize: 30, color: 'white', fontFamily:'Inter', flex: 1, textAlign:'center', fontWeight: '200', marginTop: 20}}>
            This page could not be found :(
        </div>
    </Panel>
}


export default  NotFound;

const styles = {
    title: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 25,
        color: '#CDE1FB',
        textAlign: 'center',
        width: '100%',
        marginBottom: 10
    }
}
