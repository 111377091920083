import './css/Plans.css'
import React, {useState} from 'react'
import Panel from '../Panel'
import {FiCheck, FiX} from 'react-icons/fi'



import stude_icon from '../assets/stude_icon.png'
import { ADS_GLOBAL_ENABLED } from '../hooks/constants'



const Plans = ({state, set_state}) => {

    const Field = ({name='', enabled=false}) => {
        return <div style={planFieldContainer}>
                {name}
                <div style={{marginLeft:'auto', alignSelf:'center'}}>
                    {
                        enabled == true ?
                        <FiCheck style={{color:'rgb(0, 207, 0)'}} size={30}/> :
                        <FiX style={{color:'red'}} size={30}/>
                    }
                </div>
        </div>
    }

    const Separator = () => {
        return <div style={{width:'100%', height: 0.5, backgroundColor:'#3A4958'}}/>
    }

    const Plan = ({title=null, SR=true, tags=true, notes=true, attachments=true, breaks=true, pending=true, backup=false, synchro=false, noads=false, price=null}) => {
        return <div style={planContainerStyle}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                <div style={sectionTitleStyle}>
                    {title}
                </div>
                {   price != null ?
                    <div style={priceStyle}>
                        {price}
                    </div> 
                    : null  
                }
            </div>
            <Field name='Spaced repetition' enabled={SR}/>
            <Separator />
            <Field name='Tags' enabled={tags}/>
            <Separator />
            <Field name='Notes' enabled={notes}/>
            <Separator />
            <Field name='File attachments' enabled={attachments}/>
            <Separator />
            <Field name='Take breaks' enabled={breaks}/>
            <Separator />
            <Field name='Pending lists' enabled={pending}/>
            <Separator />
            <Field name='Online backup' enabled={backup}/>
            <Separator />
            <Field name='Multi-devices' enabled={synchro}/>
            {
                ADS_GLOBAL_ENABLED == true ?
                <>
            <Separator />
            <Field name='No ads' enabled={noads}/>
                </> : null
            }
        </div>
    }
    

    return (
        <Panel>
            <div className='titlebanner'>
                <div className='bannerText'>
                    Stude, the ultimate planner for students.
                </div>
            </div>
            <div className='planscontainer' style={{display: 'flex', flexDirection:'column'}}>
                <div className='plansTitle'>
                    Different plans for different needs
                </div>
                <div style={{marginTop: 40, flexDirection:'row', display: 'flex'}}>
                    <Plan title='Stude Free'/>
                    <img src={stude_icon} style={{width: 60, height: 60, alignSelf:'center', marginLeft: 40, marginRight: 40}}/>
                    <Plan title='Stude +' backup={true} synchro={true} noads={true} /*price='1.99€/month'*/ />
                </div>
            </div>
        </Panel>
    )
}


const sectionTitleStyle = {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: 30,
    color: '#CDE1FB',
    textAlign: 'center',
    width: '100%',
    marginBottom: 20,
    marginTop: 10
}

const priceStyle = {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: 17,
    color: '#8ae3af',
    textAlign: 'center',
    width: '100%',
    marginBottom: 20,
    marginTop: 10
}


const planContainerStyle = {
    height: 450,
    width: 300,
    backgroundColor: '#4A5970',
    borderRadius: 15,
    flexDirection: 'column',
    display: 'flex',
    padding: 15,
    paddingLeft: 20,
    justifyContent:'center'
}

const planFieldContainer = {
    minHeight: 30,
    fontFamily: 'Inter',
    fontWeight: '300',
    fontSize: 20,
    color: '#CDE1FB',
    textAlign: 'left',
    width: '100%',
    flexDirection:'row',
    display:'flex',
    marginBottom: 2,
    marginTop: 8,
}


export default Plans;