import './css/PasswordResetPanel.css'
import React, {useState} from 'react'
import Panel from '../Panel'
import { useNavigate, useParams } from 'react-router-dom'
import { FiKey, FiMail } from 'react-icons/fi'
import remoteAPI from '../api/remoteAPI'
import { toast } from 'react-toastify'

const PasswordResetPanel = ({state, set_state}) => {
    let {token, email} = useParams()
    const [form, set_form] = useState({email: email, password: '', confirmPassword: ''})
    const navigate = useNavigate()

    const handleButtonClick = async () => {
        let password_regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/)
        let valid_password = password_regex.test(form.password)
        let valid_confirmPassword = (form.password == form.confirmPassword)
        if (valid_password && valid_confirmPassword) {
            try{
                const response = await remoteAPI.post('/auth/password-reset', {
                    password: form.password,
                    confirmPassword: form.confirmPassword,
                    token: token
                })
                if (response.data.success) {
                    toast.success('Password successfully reset!', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000
                    })
                    navigate('../', {replace: true})
                } else {
                    toast.error('Error, please retry.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000
                    })
            }
            } catch (err) {
                toast.error('Error, please retry.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                  })
            }
        } else {
            if (!valid_password) {
                toast.error('Password must be > 8 characters, include at least one capital letter, one number and one special character.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                  })
            } else if (!valid_confirmPassword) {
                toast.error("Passwords don't match", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                  })
            }
        }
    }
    return (
        <Panel>
            <div style={{display: "flex", flexDirection:'column', alignItems:'center', justifyContent:'center', marginTop: 30}}>
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <div style={{ display:'flex', marginTop: 20}}>
                            <FiMail size={30} style={{marginInlineEnd: 15, marginInlineStart: 20}} color='white'/>
                            <input className='field email'
                                itemID="email"
                                placeholder='Email'
                                type='text'
                                value={form.email}
                                readOnly={true}
                                //onChange={(props) => {set_form({...form, email: props.target.value })}}
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key == 'Enter') {
                                        handleButtonClick()
                                    }
                                }}
                            />
                        </div>
                        <div style={{height: 25}}/>
                        <div style={{ display:'flex'}}>
                            <FiKey size={30} style={{marginInlineEnd: 15, marginInlineStart: 20}} color='white'/>
                            <input className='field password'
                                itemID='password'
                                placeholder='New password'
                                type='password'
                                onChange={(props) => {set_form({...form, password: props.target.value })}}     
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key == 'Enter') {
                                        handleButtonClick()
                                    }
                                }}
                            />
                        </div>
                        <div style={{height: 25}}/>
                                <div style={{ display:'flex'}}>
                                <FiKey size={30} style={{marginInlineEnd: 15, marginInlineStart: 20}} color='rgba(255,255,255,0)'/>
                                    <input className='field password'
                                        itemID="password"
                                        placeholder='Confirm new password'
                                        type='password'
                                        onChange={(props) => {set_form({...form, confirmPassword: props.target.value })}}      
                                        onKeyPress={(e) => {
                                            if (e.nativeEvent.key == 'Enter') {
                                                handleButtonClick()
                                            }
                                        }}
                                    /> 
                        </div>
                    
                </div>
                <button onClick={handleButtonClick} style={styles.resetButton} className='resetbutton'>
                        Reset password
                </button>
            </div>
        </Panel>
    )
}

export default PasswordResetPanel;

const styles = {
    resetButton: {
        marginTop: 20, 
        marginLeft: 60,

    }
}