import React from 'react'
import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading,
  } from "./FooterStyles";
import {FiInstagram, FiTwitter} from 'react-icons/fi'
import stude_icon from './assets/stude_icon.png'

const Footer = () => {

    return <Box>
      <h1 style={{ color: "white", 
                   textAlign: "center", 
                   marginTop: "-50px",
                   fontFamily:'Inter',
                   fontSize: 35,
                   fontWeight:'300' }}>
        Stude, the ultimate planner for students.
      </h1>
      <Container>
        <Row>
          <Column>
            <Heading>About Us</Heading>
            <FooterLink href="mailto:support@stude.app">Contact us</FooterLink>
            <FooterLink href="/privacy-policy">Our privacy policy</FooterLink>
            <FooterLink href="/terms-of-use">Our terms of use</FooterLink>
            <FooterLink href="/legal-mentions">Legal mentions</FooterLink>
            
            {
              /*
              <FooterLink href="/general-sales-conditions">General Sales Conditions</FooterLink>
              <FooterLink href="#">Aim</FooterLink>
              <FooterLink href="#">Credits</FooterLink>
              */
            }
          </Column>
          <Column>
            <Heading>Useful Links</Heading>
            <FooterLink href="/software-licenses">Software licenses</FooterLink>
            <FooterLink href="/acknowledgements">Acknowledgements</FooterLink>
          </Column>
          <Column>
            <Heading style={{textAlign:'center'}}>Social Media</Heading>
            <FooterLink href="https://www.instagram.com/stude.app/" style={{alignItems:'center', justifyContent:'center', display: 'flex'}}>
              <i className="fab fa-instagram">
                <span style={{ marginLeft: "10px"}}>
                 <FiInstagram size={30}/>
                </span>
              </i>
            </FooterLink>
            <FooterLink href="https://twitter.com/stude_app" style={{alignItems:'center', justifyContent:'center', display: 'flex'}}>
              <i className="fab fa-twitter">
                <span style={{ marginLeft: "10px" }}>
                  <FiTwitter size={30}/>
                </span>
              </i>
            </FooterLink>
          </Column>
          <Column>
            <img src={stude_icon} alt='Stude icon' style={studeStyle}/>
          </Column>
        </Row>
      </Container>
    </Box>
}

export default Footer;

const studeStyle = {
    marginTop: 50,
    height: 80,
    width: 80,
    alignSelf:'center',
    justifySelf:'center'
}