import React from 'react';


const LegalMentions = () => {
    return <div style={styles.container}>

<strong style={styles.headerStyle}>Mentions légales</strong>
<p style={styles.bodyStyle}>
Identité de l’entreprise : MONSIEUR Ali HAWARI, Entrepreneur Individuel <br/>
Siège social de l’entreprise : 11 rue du Sudel, 68500 Guebwiller<br/>
Numéro d’immatriculation : 919 186 148<br/>
{
    //Numéro de TVA intracommunautaire : en cours d’attribution<br/>
}
Adresse mail de contact : <a href='mailto:admin@stude.app' style={styles.linkStyle}>admin@stude.app</a><br/>
Directeur de la publication : Ali HAWARI<br/>
Auteur de la publication : Ali HAWARI<br/>
</p>

<strong style={styles.headerStyle}>Identité de l’hébergeur :</strong> 
<p style={styles.bodyStyle}>
OVH<br/>
RCS Lille Métropole 424 761 419 00045<br/>
Code APE 2620Z<br/>
N° TVA : FR 22 424 761 419<br/>
Siège social : 2 rue Kellermann - 59100 Roubaix - France<br/>
Président : Michel Paulin<br/>
OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.<br/>
</p>
    </div>
}

export default LegalMentions;

const styles = {
    container: {
        padding: 20,
        paddingLeft: 40, 
        paddingRight: 40
    },
    headerStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 35,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    bodyStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    },
    linkStyle: {
        fontFamily: 'Inter',
        fontWeight: '300',
        fontSize: 18,
        color: '#CDE1FB',
        whiteSpace: 'break-spaces'
    }
}
