
import React, {useState, useEffect} from 'react'
import './App.css';
import {FiHome, FiUser, FiCheck} from 'react-icons/fi'
import HomePanel from './panels/HomePanel';
import UserPanel from './panels/UserPanel';
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { authenticate, checkSubscriptions, signin } from './hooks/hooks';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import PasswordResetPanel from './panels/PasswordResetPanel';
import WebFont from 'webfontloader';
import stude_icon from './assets/stude_icon.png'
import Plans from './panels/Plans';
import Footer from './Footer';
import TermsOfUse from './panels/TermsOfUse';
import PrivacyPolicy from './panels/PrivacyPolicy';
import NotFound from './panels/NotFound';
import PaymentSuccess from './panels/PaymentSuccess';
import EmailConfirm from './panels/EmailConfirm';
import Licenses from './panels/Licenses';
import Acknowledgements from './panels/Acknowledgements';
import LegalMentions from './panels/LegalMentions';
import GeneralSalesConditions from './panels/GeneralSalesConditions';


const cookies = new Cookies();
/*
state: {
  current_panel: 'home',
  user: {
    email: null,
    password: null,
    uuid: null,
    authenticated: false,
    subscribed: false
  },

}

*/


function App() {
  const initial_state = {
    current_panel: 'home',
    user: {
      email: '',
      password: '',
      uuid: null,
      authenticated: false,
      subscribed: null,
      next_billing_date: null,
      uuid: null,
      dbname: ''
    },
    authform: {
      type: 'signin',
      email: null,
      password: null,
      error_message: '',
      loading: true
    },
  }


  const [state, set_state] = useState(initial_state)


  useEffect(() => {
    if (state.user.uuid != null && state.user.uuid != '') {
        checkSubscriptions({state, set_state})
    }
    }, [state.user.uuid])


  useEffect(() => {
    let credentials = cookies.get('credentials')
    if (credentials != null) {
      if (credentials.email != null && credentials.password != null && state.user.authenticated == false) {
        signin({email: credentials.email, password: credentials.password, state, set_state})
        //console.log('AUTHENTICATED IN APP.JS')
      } else {
        set_state({...state, authform: {...state.authform, loading: false}})
      }
    } else {
      set_state({...state, authform: {...state.authform, loading: false}})
    }
    //console.log('COOKIES:', cookies.get('credentials'));
  }, [state.authform.authenticate_ref])

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:100,200,300,400,500,600,700']
      }
    });
  }, [])

  return (
    <div style={{minHeight: '100%', height: '100%'}}>
      
        <div className="topnav">
          <div className="title"><img src={stude_icon} alt='Stude icon' style={iconStyle}/></div>
          <a href="/"
              className={state.current_panel == 'mock' ? 'active leftHeader' : 'leftHeader'}
              style={{alignSelf:'center'}}
            >
            Home
          </a>
          <a href="/plans"
            className={state.current_panel == 'mock' ? 'active leftHeader' : 'leftHeader'}
            style={{alignSelf:'center'}}
          >
            Plans
          </a>
          <a href="https://studeapps.github.io/docs"
            className={state.current_panel == 'mock' ? 'active leftHeader' : 'leftHeader'}
            style={{alignSelf:'center'}}
          >
            Docs
          </a>
          {/*<div className="title">Stude</div> */}
          <a href="/user"
            className={state.current_panel == 'login' ? 'active rightHeader' : 'rightHeader'}
            style={{alignSelf:'center', position: 'relative', top: 3, float:'right'}}
          >
            <FiUser />
          </a>
        </div>
      <ToastContainer
      />
    <div className="pageContainer" style={{paddingTop: iconStyle.height+30}}>
      <Router>
        <Routes>
          <Route path='/' element={<HomePanel state={state} set_state={set_state}/>} />
          <Route path='/plans' element={<Plans state={state} set_state={set_state}/>} />
          <Route path='/user' element={<UserPanel state={state} set_state={set_state}/>} />

          <Route path="*" element={<NotFound />} />

          
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-use' element={<TermsOfUse />} />
          <Route path='/legal-mentions' element={<LegalMentions />} />
          {/*<Route path='/general-sales-conditions' element={<GeneralSalesConditions />} />*/}
          <Route path='/software-licenses' element={<Licenses />} />
          <Route path='/acknowledgements' element={<Acknowledgements />} />
          
          <Route path='/success/:session_id' element={<PaymentSuccess state={state} set_state={set_state}/>} />
          <Route path='/auth/confirm-email/:token' element={<EmailConfirm />} state={state} set_state={set_state} />

          <Route path='/password-reset/:token' element={<PasswordResetPanel state={state} set_state={set_state} />} />
          <Route path='/password-reset/:token/:email' element={<PasswordResetPanel state={state} set_state={set_state} />} />
          {/*<Route path='/subscribe' element={<SubscribePanel state={state} set_state={set_state}/>} />*/}
        </Routes>
      </Router>
    </div>
    
    <div className='footer'>
    <Footer />
    </div>
    </div>
  );
}

export default App;


const iconStyle = {
  height: 40,
}